.unsub_container {
  background: var(--uiux-edu-site-color-light-grey, #f4f8fb);
  min-height: 100vh;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .copywright {
    color: var(--uiux-edu-site-color-grey-dark, #939cab);
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    a {
      color: var(--uiux-edu-site-color-grey-dark, #939cab);
      font-family: Cairo;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}

.unsub_inner {
  width: 668px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--ink-white, #fff);
  margin: auto;
  padding: 40px;
  padding-top: 21px;
  h1 {
    color: #000;
    font-family: Cairo;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  p {
    color: var(--uiux-edu-site-color-grey-dark, #939cab);
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
  }
  button {
    color: var(--ink-white, #fff);
    text-align: center;
    font-family: Cairo;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 165px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--uiux-edu-site-color-blue, #085ee7);
    border: 0px;
    margin-top: 30px;
    transition: all 0.1s linear;
    &:hover {
      background: var(--uiux-edu-site-color-blue, #054fc7);
    }
  }
  @media (max-width: 600px) {
    width: calc(100% - 40px);
    h1 {
      color: #000;
      font-family: Cairo;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin: 0;
    }
    p {
      color: var(--uiux-edu-site-color-grey-dark, #939cab);
      font-family: Cairo;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }
}
