@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
:root {
  --main-color: #26e71e;
  --secondary-color: #cdd0db;
  --main-text-color: #23262b;
  --transition: all 0.3s linear;
}
* {
  font-family: "cairo" !important;
}

a {
  color: #26e71e;
}
@media (max-width: 992px) {
  html {
  }
  body {
    &::-webkit-scrollbar {
      // display: none;
    }
  }
}

ul {
  list-style: none;
}

.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  z-index: 9999;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.cursor-dot {
  width: 0px;
  height: 0px;
  background-color: rgba($color: var(--main-color), $alpha: 0.5);
}

// .cursor-dot-outline {

// }
.padding-responsive {
  padding: 0 12px;
}
.cursor-title {
  font-size: 14px;
  margin: auto;
  color: rgb(0, 0, 0);
  display: none;
  font-weight: bold;
  background: var(--main-color);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  // display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.cursor-dot-outline {
  width: 45px;
  height: 45px;
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;

  &.cursor-active-view-title {
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 15px #069601ad;
    & .cursor-bg {
      content: "";
      position: absolute;
      transform: scale(1.4);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #25e71e73;
      border-radius: 50%;
    }
    .cursor-title {
      display: flex;
    }
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.cursor {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  background-color: #3baa35ad;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  z-index: 999;
  transform: translate(-50%, -50%);
  border-radius: 50&;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.links-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
}

.container {
  width: 1250px !important;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
}
.swiper-scrollbar {
  margin: 0px 20px !important;
  position: relative;
}
.swiper-scrollbar::before {
  content: "";
  width: 20px;
  height: 100%;
  background-color: white;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 10;
  margin: 0px 20px !important;
}
.accordion-button::after {
  display: none;
}

[data-aos="new-animation"] {
  position: relative;
  min-height: inherit;
  width: 100%;
  overflow: hidden;
  img {
    width: 0;
    // height: 100%;
    min-height: inherit;
    object-fit: cover;
    transition: height 0.3s ease 0.2s, width 0.3s ease 0.6s,
      transform 0.25s ease !important;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f9fc;
    width: 10px;
    height: 0;
    transition: height 0.3s ease 0.1s, width 0.3s ease 0.4s,
      transform 0.3s ease 1s !important;
    z-index: 111;
  }

  &.aos-animate {
    &::after {
      width: 100%;
      height: 100%;
      transform: translateX(100%);
    }
    img {
      width: 100%;
    }
  }
}

.header_one {
  h1 {
    white-space: nowrap;
  }
}
