.info_card {
  width: 460px;
  // height: 516px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 0 auto;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: -430px;
  margin-left: 55px;
  box-shadow: 0px 10px 40px -5px rgba(0, 0, 0, 0.1);
  top: 130px !important;
  @media only screen and (max-width: 600px) {
    margin-top: 30px;
  }
  .info_card_head {
    padding: 0 30px;
    .info_card_head_date {
      span {
        font-size: 18px;
        padding-left: 5px;
      }
    }
    .info_card_head_title {
      font-size: 24px;
      font-weight: 700;
    }
    .info_card_head_cearor {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
    }
  }
  .info_card_av {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 20px 30px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    margin-top: 30px;
    p {
      font-size: 16px;
      margin: 0;
      color: rgb(97, 97, 97);
    }
  }
  .info_card_desc {
    p {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      padding: 30px;
      padding-bottom: 0;
    }
  }
  .info_card_btns {
    padding-left: 30px;
    padding-right: 30px;
    button,
    a {
      display: block;
      width: 100%;
      height: 60px;
      border: none;
      background-color: transparent;
      transition: all 0.3s linear;
      border-radius: 12px;
      text-decoration: none;
      color: #000;
      &.get_in_touch {
        border: 1px solid #3aaa35;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        &:hover {
          color: #fff;
          background-color: #3aaa35;
        }
      }
      &.download {
        background: #e4ebf3;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: #000;
        }
        cursor: pointer;
      }
    }
  }
}
