.links_header_container {
  position: relative;
  // background-image: url("../../../../public/images/layouti/links_header.png");
  background-size: cover;
  background-position: top;
  // background-position-y: -200px;
  background-repeat: no-repeat;
  @media only screen and (max-width: 600px) {
    background-position-y: -70px;
  }
  .links_header {
    padding-top: 190px;
    img {
      display: block;
      width: 200px;
      height: 200px;
      margin: auto;
      border-radius: 50%;
      @media only screen and (max-width: 600px) {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
    h3 {
      font-family: Cairo;
      font-size: 36px;
      font-weight: 700;
      line-height: 43px;
      letter-spacing: 0em;
      text-align: center;
      color: #00122f;
      width: 500px;
      margin: auto;
      @media only screen and (max-width: 600px) {
        width: calc(100% - 40px);
        color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
        text-align: center;
        font-family: Cairo;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
      }
    }
    p {
      width: 690px;
      margin: 0 auto;
      margin-top: 26px;
      color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
      text-align: center;
      font-family: Cairo;
      font-size: 24px;
      line-height: 34px;
      font-style: normal;
      font-weight: 400;
      @media only screen and (max-width: 600px) {
        width: calc(100% - 40px);
        color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
        text-align: center;
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .absolute__imgs {
    position: absolute;
    opacity: 0.2;
    transition: all 0.4s linear;
    border-radius: 10px;
    display: block;
    &:hover {
      opacity: 1;
    }
    &:first-of-type {
      right: 100px;
      top: 0;
      width: 200px;
    }
    &:nth-of-type(2) {
      left: 0;
      top: 0;
      width: 200px;
    }
    &:nth-of-type(3) {
      left: -100px;
      bottom: 0;
      width: 150px;
    }
    &:nth-of-type(4) {
      right: -100px;
      bottom: 0;
      width: 150px;
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .emoji__imgs {
    position: absolute;
    transition: all 0.4s linear;
    width: 80px;
    top: 0;
    right: 0;
    &:last-of-type {
      left: 50px;
      top: 90%;
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}

.main-img {
  border-radius: 50%;
}

.social_media_links {
  margin-top: 100px;
  margin-bottom: 100px;
  font-size: 30px;
  font-weight: 700;
  h1 {
    font-family: Cairo;
    font-size: 30px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: center;
    color: #00122f;
    @media only screen and (max-width: 600px) {
      color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
      text-align: left;
      font-family: Cairo;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 33px;
      padding-left: 20px;
    }
  }
}
.social_media_link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto !important;
  width: 576px;
  height: 80px;
  font-weight: 600;
  border: 1px solid #3aaa35;
  border-radius: 10px;
  font-size: 21px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #00122f;
  &:hover {
    background-color: #3aaa35;
    color: #fff;
  }
  @media only screen and (max-width: 600px) {
    width: calc(100% - 40px);
  }
}

@keyframes trans {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.contact_info_Section {
  margin-top: 80px;
  h2 {
    color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
    text-align: center;
    font-family: Cairo;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
    @media only screen and (max-width: 600px) {
      color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
      text-align: left;
      font-family: Cairo;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 20px;
    }
  }
  .contact_cards {
    .contact_cards_link {
      width: 576px;
      height: 80px;
      margin: auto;
      display: block;
      margin-bottom: 20px;
      color: var(--uiux-edu-site-color-dark-blue-1, #00122f);
      font-family: Cairo;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration: none;
      .contact_cards_link_inner {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 1px solid var(--uiux-edu-site-color-grey, #c3c9d2);
        background: var(--uiux-edu-site-color-white, #fff);
        width: 100%;
        height: 100%;
        padding-left: 30px;
        span {
          gap: 20px;
          display: flex;
          width: 50%;
          @media only screen and (max-width: 600px) {
            width: 100%;
          }
        }
        @media only screen and (max-width: 600px) {
          padding-block: 10px;
        }
      }
      @media only screen and (max-width: 600px) {
        width: calc(100% - 40px);
        height: auto;
      }
    }
  }
}
