.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  transition: all 0.15s linear;
  opacity: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
}
.popup-active {
  opacity: 1;
  z-index: 100000;
}
.popup-background-color {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0,0,0,.7); */
  z-index: 1;
}
.popup-container {
  position: absolute;
  top: 60px;
  left: 50%;
  width: 430px;
  max-height: 100%;
  margin: auto;
  background-color: white;
  border-radius: 20px;
  text-align: left;
  padding: 30px;
  padding-bottom: 40px;
  transform: translateX(-50%) scale(0);
  transition: all 0.2s linear;
  z-index: 2;
}
.popup-active .popup-container {
  /* transform:translateX(-50%) scale(1); */
  animation: openPopUpAnimation 0.2s linear forwards;
}
.popup-header {
}
.popup-header__title {
  font-size: 21px;
  font-weight: 700;
  color: #000000;
  width: 100%;
  margin: 0 auto 20px;
}
.popup-header__desc {
  font-size: 20px;
  font-weight: 400;
  color: #23262b;
  /* margin-bottom: 37px; */
  width: 70%;
  margin: 0 auto 37px;
}
.popup-header__error {
  font-size: 30px;
  font-weight: 400;
  color: #23262b;
  /* margin-bottom: 37px; */
  width: 70%;
  margin: 0 auto 37px;
}
.popup-image__container {
}
.popup-image__icon {
  margin-bottom: 40px;
}
.popup-footer__close-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.popup-footer__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid Green;
  width: 50%;
  height: 45px;
  font-size: 18px;
  background-color: white;
  margin: auto;
}
.popup-footer__submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 1px solid #26e71e;
  width: 50%;
  height: 45px;
  font-size: 18px;
  background-color: #26e71e;
  margin: auto;
  font-weight: 700;
}
@keyframes openPopUpAnimation {
  0% {
    transform: translateX(-50%) scale(0);
  }
  50% {
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
@media (max-width: 426px) {
  .popup-container {
    width: 350px;
    top: 30px;
    /* bottom: 10px; */
  }
  .popup-image__icon {
    margin-bottom: 20px;
  }
  .popup-header__title {
    margin: 0 auto 17px;
  }
}

.contact-us__hire-us-form {
  margin-bottom: 200px;
  transition: var(--transition);
}
.contact-us__hire-us-form--hidden {
  opacity: 0;
  z-index: -1;
  position: absolute;
}
.contact-us__form-field-cont {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.contact-us__form-field-label {
  font-size: 16px;
  font-weight: 400;
  font-family: "Cairo";
  margin-bottom: 5px;
  display: block;
  margin-top: 10px;
  color: var(--main-text-color);
}

.contact-us__form-field-input {
  padding: 5px 10px;
  height: 45px;
  outline: 0;
  border-radius: 8px;
  border: 1px solid var(--secondary-color);
  width: 100%;
  margin-bottom: 30px;
}
.contact-us__form-field-need-cont {
  display: flex;
  flex-wrap: wrap;
}
.contact-us__form-field-select-cont {
}
.contact-us__check-box-label {
  margin: 0 20px 15px 0;
  background-color: white;
  border: 1px solid var(--main-color);
  padding: 17px 35px;
  border-radius: 40px;
  color: var(--main-text-color);
  cursor: pointer;
}
.contact-us__check-box:checked ~ .contact-us__check-box-label {
  background-color: var(--main-color);
  color: white;
}
.contact-us__check-box {
  display: none;
}
.contact-us__form-field-textarea {
  padding: 15px 25px;
  outline: 0;
  border-radius: 30px;
  border: 1px solid var(--secondary-color);
  outline: 0;
  margin-bottom: 20px;
}
.contact-us__form-field-input-file {
  display: none;
}
.contact-us__form-field-file--upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-color);
  margin-bottom: 10px;
}
.contact-us__file-upload-icon {
  width: 25px;
  color: #b1b6c9;
}
.contact-us__file-upload-note {
  color: #b1b6c9;
}
.contact-us__form-field-privacy-cont {
  flex-direction: row;
  align-items: center;
}
.contact-us__terms-conditions-label {
  font-size: 24px;
  display: flex;
  align-items: center;
}
.contact-us__form-field-privacy {
  color: var(--main-color);
  transition: var(--transition);
  padding-left: 2px;
}
.contact-us__form-field-privacy:hover {
  color: var(--main-color);
}
.contact-us__submit-form {
  background-color: var(--main-color);
  color: rgb(0, 0, 0);
  border: 0;
  font-weight: bold;
  border-radius: 30px;
  width: 240px;
  height: 50px;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us__submit-form:hover {
  animation: ButtonAnimation 0.3s linear 1;
}
.contact-us__terms-conditions-input {
  display: none;
}
.contact-us__terms-conditions-input-shown {
  height: 50px;
  width: 50px;
  border-radius: 15px;
  margin-left: 3px;
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  margin-right: 20px;
  margin-inline-end: 20px;
}
.contact-us__terms-conditions-input:checked
  ~ .contact-us__terms-conditions-label
  .contact-us__terms-conditions-input-shown {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}
.contact-us__terms-conditions-icon {
  color: white;
}
.contact-us__form-field-required {
  color: #ff0000;
  border-color: #ff0000;
}

@media (max-width: 992px) {
  .contact-us__submit-form-cont {
    display: flex;
    width: 100%;
  }
  .contact-us__submit-form {
    margin: auto;
  }
  .contact-us__hire-us-form {
    margin-bottom: 100px;
  }
}
@media (max-width: 426px) {
  .contact-us__terms-conditions-label {
    font-size: 20px;
  }
  .contact-us__terms-conditions-input-shown {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-inline-end: 10px;
    border-radius: 10px;
  }
  .contact-us__check-box-label {
    margin: 0px 10px 10px 0;
    padding: 13px 30px;
  }
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="passowrd"]::placeholder,
textarea::placeholder {
  color: var(--secondary-color);
}

@keyframes ButtonAnimation {
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 376px) {
  .contact-us__submit-form-cont {
    margin: 0;
    width: 100%;
  }
  .contact-us__submit-form {
    margin: 0;
    width: 100%;
  }
}
